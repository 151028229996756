
import Vue from 'vue'

export default {
    name: 'Footer',
    data() {
        return {
            hasLogin: '',
            deviceId: '',
            active: '',
            bag: {
                quantity: '',
            },
            list: [],
            bgStyle: {}
        }
    },
    async fetch() {
        if(this.list.length) return
        const res = await this.$api.mixins.getBottomNav();
        this.bottom = res?.result || {}
        this.setData()
        this.setStyle()
        this.setActive()
    },
    created() {},
    mounted() {
        this.initialSet()
        this.deviceId && this.getUserBagQuantityAjax()
    },
    watch: {
        $route(to, form) {
            this.setActive()
        }
    },
    methods: {
        // 获取购物车
        getUserBagQuantityAjax() {
            this.$api.bag.getUserBagQuantity().then(response => {
                let res = response.result || {};
                this.bag.quantity = res.count || ''
            })
        },
        // tab change
        changeTab(e) {
            let history = sessionStorage.getItem('history');
            history && (history = history.toLowerCase());
            let query = '';
            if(['product'].includes(history)) {
                query = `?from=${history}`
            }
            if(e.includes('community')) {
                window.location.href = '/community/videolist'
            } else {
                this.$router.push({
                    path: `${e}${query}`
                })
            }
        },
        // 设置数据
        setData() {
            const nameArray = ['category', 'community', 'shoppingbag', 'mine']
            this.list = [];
            this.bottom.titleList && this.bottom.titleList.forEach(item => {
                const name = nameArray.filter(c => {
                    return item.jumpUrl.includes(c)
                })[0] || '';
                this.list.push({
                    name: `/${name}`,
                    value: item.title,
                    icon: item.unselectedPicUrl,
                    activeIcon: '',
                    useIcon: item.selectedPicUrl,
                    jumpUrl: item.jumpUrl,
                    color: item.unselectedTitleColor,
                    colorActive: item.selectedTitleColor,
                    hasBadge: item.jumpUrl.includes('shoppingbag'),
                    hasJump: item.jumpUrl.includes('community')
                })
            })
        },
        setStyle() {
            if(this.bottom.backgroundStyle == 2) {
                this.bgStyle = {background: `url(${this.bottom.picUrl}) no-repeat center center / cover`}
            } else {
                this.bgStyle = {background: this.bottom.backgroundColor || '#fff'}
            }
        },
        // 设置tab状态
        setActive() {
            const currentPath = this.$route.name.toLocaleLowerCase();
            this.list.forEach(item => {
                item.activeIcon = ''
                if(item.name.includes(currentPath)) {
                    item.activeIcon = item.useIcon
                }
            })
            if(currentPath == 'home' && this.list.length) {
                this.list[0].activeIcon = this.list[0].useIcon
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.deviceId = this.$storage.get('deviceId')
            Vue.prototype.$updateBodyBagQuantity = this.getUserBagQuantityAjax
        }
    },
}
