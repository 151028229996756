
export default {
    name: 'BizBrand',
    props: {
        isEnsure:{
            default:true,
            type:Boolean
        }
    },
    data() {
        return {
            biz: {
                testNum: 0,
            },
            country: {},
            collapse: {
                active: [1, 2, 5, 6],
                visible: true
            },
            ensure: {
                list: []
            },
            policy: {
                list: []
            },
            company: {
                list: []
            },
            brand: {
                list: []
            },
            media: {
                list: []
            },
            payIcon: { // 支付图标
                list: []
            },
            subscribe: { // 订阅
                hasSubscribe: false,
                email: '',
                tips: '',
                hasCheck: false, // 订阅是否家校验通过
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getPayIconAjax()
    },
    methods: {
        // 邮件订阅
        subscribeAjax() { // 订阅接口
            this.$api.help.setSubscribe({
                data: JSON.stringify({email: this.subscribe.email})
            }).then(res => {
                this.subscribe.hasSubscribe = true
                this.$Toast.success(this.translate('This email has already subsribed'))
            })
        },
        handleSubscribe() { // 订阅
            this.checkSubscribe()
            if(!this.subscribe.hasCheck) {
                return
            }
            this.subscribeAjax()
        },
        inputEmail() { // 订阅输入
            this.checkSubscribe()
        },
        checkSubscribe() { // 订阅校验
            let pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            this.subscribe.tips = ''
            if(!this.subscribe.email) {
                this.subscribe.tips = this.translate('Please enter your email address')
            }
            if(!pattern.test(this.subscribe.email)) {
                this.subscribe.tips = this.translate('Please enter a valid email address')
            }
            this.subscribe.hasCheck = !this.subscribe.tips
        },
        // 支付图标
        getPayIconAjax() {
            this.$api.common.getCountryPayIcon({
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response?.result?.result || {};
                const mSupportPaymentIcon = res.mSupportPaymentIcon ? res.mSupportPaymentIcon.split(',') : [];
                this.payIcon.list = []
                mSupportPaymentIcon.forEach(p => {
                    this.payIcon.list.push({
                        pic: p
                    })
                })
                this.payIcon.list = [
                    ...this.payIcon.list,
                    ...this.brand.list
                ]
            })
        },
        // 折叠面板
        changeCollapse(e) {
            this.collapse.visible = e
        },
        // 跳转
        goLink(obj) {
            if(obj.url) {
                window.location.href = obj.url
                return
            }
            this.$router.push({
                name: obj.name,
                query: obj.query || {}
            })
        },
        jumpMedia(obj) {
            window.location.href = obj.url
        },
        // 翻译
        translate(val) {
            return this.$translate(val)
        },
        // 测试
        handleTest() {
            const openTest = this.$storage.get('biz/openTest')
            if(openTest) return
            this.biz.testNum += 1
            if(this.biz.testNum < 2) {
                let clear = setTimeout(() => {
                    this.biz.testNum = 0
                    clearTimeout(clear)
                }, 1000)
            }
            if(this.biz.testNum >= 5) {
                this.biz.testNum = 0
                window.setOpenTest()
            }
        },
        // 初始化
        initialSet() {
            this.country = this.$storage.get('country') || {}
            this.ensure.list = [{
                icon:'icon-ensure-light',
                text: this.$translate('Secured Payment')
            }, {
                icon:'icon-return-light',
                text: this.$translate('60-Days Return')
            }, {
                icon:'icon-free',
                text: this.$translate('Free Shipping')
            }, {
                icon:'icon-customer-light',
                text: this.$translate('24H Service')
            }]
            this.policy.list = [{
                text: this.translate('Shipping Policy'),
                name: 'Policy',
                query: {
                    id: '1679369506448H4KdPCGQ'
                }
            }, {
                text: this.translate('Return Policy'),
                name: 'Policy',
                query: {
                    id: '1679369573013aP5MjpvZ'
                }
            }, {
                text: this.translate('Terms&Conditions'),
                name: 'Policy',
                query: {
                    id: '167936955283278Vtn7Mz'
                }
            }, {
                text: this.translate('Payment Method'),
                name: 'Policy',
                query: {
                    id: '1679369593929FHpv8nbx'
                }
            }, {
                text: this.translate('Privacy Policy'),
                name: 'Policy',
                query: {
                    id: '16793696198020vLSBIcB'
                }
            }, {
                text: this.translate('Promo codes & Coupons'),
                name: 'CouponDiscount'
            }, 
            // {
            //     text: this.translate('Site Map'),
            //     name: 'Sitemap'
            // }, 
            {
                text: this.translate('Track Your Order'),
                name: 'TrackSearch',
                query: {
                    from: 'footer'
                }
            }]
            this.company.list = [{
                text: this.translate('About Us'),
                name: 'AboutUs',
            }, {
                text: this.translate('Contact Us'),
                name: 'ContactUs'
            }, {
                text: this.translate('YFN Blog'),
                url: 'https://www.yfn.com/blog/?channel=footer'
            }, {
                text: this.translate('Sustainability'),
                url: 'Sustainability'
            }]
            this.brand.list = [{
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107956460.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107963431.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107971691.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107982376.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107989067.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107991820.png',
            }]
            this.media.list = [{
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1693876474213.png',
                url: 'https://www.facebook.com/YFNCOM'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1693876477608.png',
                url: 'https://www.instagram.com/yfnjewelry_official'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1693876480070.png',
                url: 'https://www.pinterest.com/YFNCOM'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1693876482659.png',
                url: 'https://www.tiktok.com/@yfn_uk_official'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1693876486518.png',
                url: 'https://www.youtube.com/channel/UCikbSP-wQtjFQJ1U2yzrDbQ'
            }]
        }
    },
}
