
export default {
    name: 'CustomizeIframe',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            popupStyle: {},
            defaultOn: {}
        }
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.setPopup()
            this.setCustomizeFn()
        },
        setCustomizeFn() {
            const onObj = this.options.on || {};
            window.customizeApi ?? (window.customizeApi = {})
            this.setDefaultCustomizeFn()
            Object.keys(onObj).forEach(key => {
                window.customizeApi[key] = onObj[key]
            })
        },
        setDefaultCustomizeFn() {
            this.defaultOn = {
                getStorage: this.getStorage,
                jumpBack: this.jumpBack,
                jumpBag: this.jumpBag,
            }
            Object.keys(this.defaultOn).forEach(key => {
                window.customizeApi[key] = this.defaultOn[key]
            })
        },
        // 设置iframe内嵌弹窗
        setPopup() {
            const visible = this.options.visible;
            const style = visible ? {} : {height: 0,opacity: 0,overflow: 'hidden'};
            this.popupStyle = style
            this.setBody()
        },
        setBody() {
            const visible = this.options.visible && this.options.url;
            const el = document.querySelector('body');
            if(!el) return
            visible ? el.classList.add('van-overflow-hidden') : el.classList.remove('van-overflow-hidden')
        },
        // 默认定制api
        getStorage(options, callback) { // 缓存
            callback && callback({
                params: localStorage
            })
        },
        jumpBack(options) { // 返回/关闭
            this.options.visible = false
            this.setBody()
        },
        jumpBag(options) { // 跳转购物车
            this.options.visible = false
            this.setBody()
            this.$router.push({
                name: 'Bag'
            })
        },
        // popup
        getContainer() {
            return document.querySelector('.CustomizeIframe')
        }
    },
}
