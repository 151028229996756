import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=1f819d50&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=1f819d50&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f819d50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BizRegister: require('/home/php1/m-nuxt/components/BizRegister.vue').default})
