import { render, staticRenderFns } from "./Quick.vue?vue&type=template&id=ab3e91fa&scoped=true&"
import script from "./Quick.vue?vue&type=script&lang=js&"
export * from "./Quick.vue?vue&type=script&lang=js&"
import style0 from "./Quick.vue?vue&type=style&index=0&id=ab3e91fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab3e91fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnVideo: require('/home/php1/m-nuxt/components/YfnVideo.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
