
export default {
    name: 'FilterPopup',
    props: {
        config: Object,
        data: Object,
    },
    data() {
        return {
            i: 0,
            clear: '',
            currency: {},
            rsData: {},
            form: {
                params: {},
                options: {},
                radioOptions: {},
                priceOption: [],
                weight: [0, 0],
                price: [0, 0],
                priceId: '',
                changeSlideNum: 0,
                hasClear: false,
                salePriceMin: '',
                salePriceMax: '',
            },
            visible: false,
            hasChange: false,
            hasMore: true,
            list: [],
            interval: {},
            collapse: [-3,-2,-1, 0, 1, 2, 3, 4],
            activityFilter:{},
            backCatFilter:{},
            commentScoreFilter:{},
            activityDate:'',
            backCatDate:[],
            commentDate:'',
            commentValue:'',
            activityValue:''
        }
    },
    computed: {},
    watch: {
        data: {
            handler(n, o) {
                this.setFilterData(n)
            },
            deep: true,
            immediate: true
        },
        'data.salePriceMin': {
            handler(n, o) {
                this.form.price[0] = n
            },
            immediate: true
        },
        'data.salePriceMax': {
            handler(n, o) {
                this.form.price[1] = n
            },
            immediate: true
        },
        config: {
            handler(n, o) {
                n.hasReset && this.handleDelete()
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        changeBackCat(){
            this.setParamsClear()
        },
        handleActivity(obj){
            if(this.activityValue == obj.type){
                this.activityDate = ''
                this.activityValue = ''
            }else {
                this.activityValue = this.activityDate
            }
            this.setParamsClear()
        },
        handleComment(obj){
            if(this.commentValue == obj.level){
                this.commentDate = ''
                this.commentValue = ''
            }else {
                this.commentValue = this.commentDate
            }
            this.setParamsClear()
        },
        // 选项数据处理
        setFilterData(options) {
            if(!options) return
            let obj = JSON.parse(JSON.stringify(options));
            this.rsData = obj
            this.i += 1
            // 商品区间
            this.interval = {
                salePriceMax: Math.round(obj.salePriceMax),
                salePriceMin: Math.floor(obj.salePriceMin),
                weightMax: 20,
                weightMin: 0
            }
            this.form.weight = [0, 20]
            // this.form.price = [this.interval.salePriceMin, this.interval.salePriceMax]
            // 选项数据
            this.list = []
            if(obj.propsFilterList) {
                obj.propsFilterList.forEach(item => {
                    item.children.forEach(c => {
                        this.list.push(c)
                    })
                })
            }
            this.activityFilter = obj.activityFilter
            this.backCatFilter = obj.backCatFilter
            this.commentScoreFilter = obj.commentScoreFilter
            this.list.forEach((c, cI) => {
                c.key = `prop${c.propName}`
                c.type = c.yfnTCChooseDataType == 'radio' ? 1 : 2
                if(this.hasMore) {
                    c.hasMore = c.children.length > 15
                    c.children.forEach((g, gI) => {
                        g.disabled = gI > 14
                    })
                }
                // options数据设置
                this.setFilterOptions(c)
                this.setInitialFilterOptions(c)
            })
            this.visible = this.list.length
            this.hasChange = true
            let clear = setTimeout(() => {
                this.hasChange = false
                clearTimeout(clear)
            }, 500)
        },
        setFilterOptions(obj) {
            if(!this.form.options.hasOwnProperty(obj.key)) {
                delete this.form.options[obj.key]
            } else {
                if(!this.form.options[obj.key]) {
                    this.form.options[obj.key] = obj.type == 1 ? '' : []
                }
            }
        },
        // 初始化options选择
        setInitialFilterOptions(obj) {
            let io = this.rsData.initialOptions
            if(this.i > 1) {
                return
            }
            if(io) {
                Object.keys(io).forEach(key => {
                    if(io[obj.key] && !this.form.options.hasOwnProperty(key)) {
                        this.$set(this.form.options, key, io[key])
                        obj.type == 1 && this.$set(this.form.radioOptions, key, io[key])
                    }
                })
            }
        },
        // 查看更多
        handleOption(c) {
            this.list.some((item, i) => {
                if(item.key == c.key) {
                    item.hasMore = false
                    item.children.forEach(list => {
                        list.disabled = false
                    })
                    this.$set(this.list, i, {...item})
                    return true
                }
            })
        },
        // 滑块
        changeSlider(e) {
            this.form.changeSlideNum += 1
            this.setParamsClear()
        },
        // 价格区间
        setConvert(v) {
            // 金额去除前位0，小数保留最多两位
            if(!v) return v
            let vArr = [];
            const arr = String(v).split('.');
            const first = String(arr[0] || '');
            const last = String(arr[1] || '') || '';
            const lastV = last ? last.slice(0, 2) : '';
            [...first].some((p, i) => {
                if(Number(p)) {
                    vArr.push(first.slice(i))
                    return true
                }
            })
            lastV && (vArr.push(lastV))
            const rV = vArr.join('.');
            return rV
        },
        inputPrice() {
            this.form.priceId = ''
            this.form.salePriceMin = this.setConvert(this.form.salePriceMin)
            this.form.salePriceMax = this.setConvert(this.form.salePriceMax)
            this.setPrice()
        },
        handlePrice(obj) {
            this.form.priceId = this.form.priceId == obj.id ? '' : obj.id
            const priceId = this.form.priceId;
            const optionValue = priceId ? this.form.priceOption[priceId - 1] : {};
            const priceValue = optionValue.value || [];
            this.form.price = priceValue
            this.form.salePriceMin = priceValue[0] || ''
            this.form.salePriceMax = priceValue[1] || ''
            this.form.changeSlideNum = priceValue.length ? 1 : 0
            this.setParamsClear()
        },
        setPrice() {
            const numMin = Number(this.form.salePriceMin);
            const numMax = Number(this.form.salePriceMax);
            if(numMin > numMax && this.form.salePriceMin && this.form.salePriceMax) {
                let temp = this.form.salePriceMin;
                this.form.salePriceMin = this.form.salePriceMax
                this.form.salePriceMax = temp
            }
            const min = this.form.salePriceMin;
            const max = this.form.salePriceMax;
            this.form.price = [min, max]
            this.form.changeSlideNum = min || max ? 1 : 0
            this.setParamsClear()
        },
        // 选项
        changeOptions(e, obj) {
            !this.hasChange && this.setParamsClear()
        },
        handleRadio(e, obj, g) {
            if(obj.type == 1) {
                if(this.form.options[obj.key] == g.propValue) {
                    this.form.options[obj.key] = ''
                    this.form.radioOptions[obj.key] = ''
                } else {
                    this.form.options[obj.key] = g.propValue
                    this.form.radioOptions[obj.key] = g.propValue
                }
                this.setParamsClear()
            }
        },
        // 关闭弹窗
        handleClose() {
            this.config.visible = false
        },
        // 重置
        handleDelete() {
            this.config.hasReset = false
            this.form.hasClear = true
            this.form.params = {}
            this.form.weight = [0, 0]
            // this.form.price = [this.interval.salePriceMin || 0, this.interval.salePriceMax || 0]
            this.form.priceId = ''
            this.form.price = []
            this.form.salePriceMin = ''
            this.form.salePriceMax = ''
            this.form.changeSlideNum = 0
            this.activityDate = ''
            this.backCatDate = []
            this.commentDate = ''

            Object.keys(this.form.options).forEach(key => {
                if(Array.isArray(this.form.options[key])) {
                    this.form.options[key] && (this.form.options[key] = [])
                } else {
                    this.form.options[key] && (this.form.options[key] = '')
                }
                this.form.radioOptions[key] && (this.form.radioOptions[key] = '')
            })
            this.setParamsClear()
            let clear = setTimeout(() => {
                this.form.hasClear = false
                clearTimeout(clear)
            }, 100)
        },
        // 设置筛选数据-clear
        setParamsClear() {
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.setParams()
            }, 60)
        },
        // 设置筛选数据
        setParams() {
            this.hasMore = false
            // 筛选项
            let propsString = []
            let opKey = this.form.options
            this.form.params = {}
            this.list.forEach(item => {
                let propValueList = [];
                let propCodeList = [];
                if(opKey[item.key]) {
                    if(item.type == 1) {
                        propValueList.push(opKey[item.key])
                    } else {
                        propValueList = [...opKey[item.key]]
                    }
                    if(propValueList.length) {
                        if(item.children) {
                            item.children.forEach(c => {
                                if(propValueList.includes(c.propValue)) {
                                    propCodeList.push(c.valueCode)
                                }
                            })
                        }
                        propsString.push({
                            from: 'erp',
                            propName: item.propName,
                            nameCode: item.nameCode,
                            valueCodes: propCodeList,
                            propValueList,
                        })
                    }
                }
            })
            if(propsString.length) {
                this.form.params.propsString = JSON.stringify(propsString)
            }
            // 区间
            // if(!!this.form.price.length) {
            //     this.form.params.salePriceMin = this.form.price[0] || 0
            //     this.form.params.salePriceMax = this.form.price[1] || 999999
            // }
            this.form.params.salePriceMin = this.form.salePriceMin || 0
            this.form.params.salePriceMax = this.form.salePriceMax || 999999
            
            if(this.form.weight[1]) {
                this.form.params.weightMin = this.form.weight[0]
                this.form.params.weightMax = this.form.weight[1]
            }
            // 清空
            if(this.form.hasClear) {
                this.form.params.propsString = ''
                this.form.params.salePriceMin = ''
                this.form.params.salePriceMax = ''
            }
            if(this.commentDate){
                this.form.params.filterCommentScore = Math.floor(this.commentDate)
            }else{
                this.form.params.filterCommentScore = ''
            }
            if(this.activityDate){
                this.form.params.filterActivity = Math.floor(this.activityDate)
            }else{
                this.form.params.filterActivity = ''
            }
            if(this.backCatDate?.length){
                this.form.params.filterCats = this.backCatDate.join(',')
            }else{
                this.form.params.filterCats = ''
            }
            this.sessionFilter()
            this.$emit('emit-filter', {
                form: this.form,
                list: this.list,
                data: this.rsData,
                interval: this.interval
            })
        },
        // 搜索
        handleApply() {
            this.config.visible = false
        },
        // 缓存数据
        sessionFilter() {
            if(process.client) {
                sessionStorage.setItem('filter', JSON.stringify(this.form.options))
            }
        },
        // 初始化
        initialSet() {
            const currency = this.$storage.get('currency');
            this.currency = currency
            this.form.priceOption = [{
                value: ['', 50],
                id: 1,
                label: `${this.$translate('Under')} ${currency.symbol}50`
            }, {
                value: [50, 100],
                id: 2,
                label: `${currency.symbol}50 - ${currency.symbol}100`
            }, {
                value: [100, 300],
                id: 3,
                label: `${currency.symbol}100 - ${currency.symbol}300`
            }, {
                value: [300, 500],
                id: 4,
                label: `${currency.symbol}300 - ${currency.symbol}500`
            }, {
                value: [500, ''],
                id: 5,
                label: `${this.$translate('Over')} ${currency.symbol}500`
            }]
        },
    },
}
