
export default {
    name: 'BizRegister',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        type: {
            type: [String, Number],
            default: () => {
                return 1
            }
        }
    },
    data() {
        return {
            hasLogin: false,
            hasTip: false,
            hasRegister: false,
            hasAssignPage: false,
            assignPage: ['Home', 'Product', 'Product-Old', 'Bag', 'SitemapList', 'CategoryDetail', 'Category-Seo','CategoryDetail-Seo']
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        !this.hasLogin && this.hasAssignPage && this.getRegisterStatus()
    },
    methods: {
        getRegisterStatus() {
            this.$api.user.getRegisterStatus().then(response => {
                const res = response.result || {};
                const hasRefresh = this.$store.state.biz.hasRefresh;
                const expireTime = this.$storage.get('user/registerTipTime');
                const nowTime = this.$dayjs().valueOf();
                this.hasTip = !this.hasLogin && (!expireTime || nowTime > expireTime)
                this.hasRegister = res != 'sign'
            })
        },
        handleRegister() {
            this.setRegisterTip()
            this.$router.push({
                name: 'Login'
            })
        },
        handleClose() {
            this.setRegisterTip()
        },
        setRegisterTip() {
            const nowTime = this.$dayjs().valueOf();
            const expireTime = nowTime + (1 * 5 * 60 * 1000);
            this.hasTip = false
            this.$storage.set('user/registerTipTime', expireTime)
        },
        // 初始化
        initialSet() {
            const hasAssignPage = this.assignPage.includes(this.$route.name);
            this.hasLogin = this.$login()
            this.hasAssignPage = hasAssignPage
        }
    },
}
