
import md5 from '/utils/md5';
import Validate from '/utils/validate'

const validate = new Validate()

export default {
    name: 'Login',
    props: {
        popup: {
            type: Object,
            default: () => {
                return {
                    visible: false,
                    hasReload: false
                }
            }
        },
    },
    data() {
        return {
            skeleton: {                      // 骨架屏
                visible: false
            },
            tab: {                           // tab标签
                type: 1,                     // 1 注册 2 登录
            },
            see: {                           // 密码查看
                status: false
            },
            tip: {
                hasPwdTip: false,           // 是否显示密码提示
                list: [],                   // 密码校验提示
            },
            form: {
                hasAllInput: false,          // 是否均填写
                hasEmail: false,             // email是否校验通过
                hasEmailTip: false,          // email提示是否显示
                hasEmailOption: false,       // email选择项是否显示
                hasPwdNum: true,             // 密码是否包含数字
                hasPwdEn: true,              // 密码是否包含字母
                hasPwd: false,               // 密码是否验证通过
                hasPasswordTip: false,       // 密码提示是否显示
                hasCheck: false,             // 密码&邮箱是否校验通过
                emailSuffix: [],             // 邮箱后缀
                emailOptions: [],            // 邮箱选项
                inputType: 1,                // 输入框类型 1 邮箱 2 密码
                clear: '',
                pwdNum: 0,                   // 用户密码输入次数累计
                isPwd: false,
            },
            params: {                        // 参数
                email: '',
                password: '',
                emailSelect: '',
                agree: false,
                md5Password: '',             // 加密后的密码
                registerSource: 5,           // 注册来源
            },
            isContinue:false,
            loading: false,
            isloading:false,
            emailForm:{
                emailPopup:false,
                emailInput:'',
                isEmailMsg:false,
                info:{},
                isSuccess:false
            },
            isGoogle:true,
            isDis:false
        }
    },
    head() {
        return {
            script: [{
                src: 'https://cdn3.selleroa.com/yfn-m/js/jsencrypt.min.js',
                async: true,
                defer: true
            },{
                src: 'https://accounts.google.com/gsi/client',
                async: false
            }]
        }
    },
    created(){
        window.onGoogleLibraryLoad = () => {
            this.isGoogle = false
            this.loadGoogle()
        }
    },
    mounted() {
        this.initialSet()
    },
    methods: {
        pwdClick(){
            this.form.isPwd = false
            this.jumpChangePassword()
        },
        onFBLogin() {
            const _this = this
            try {
                FB
            } catch (error) {
                return false
            }
            // 检测FB登录状态
            FB.getLoginStatus(function(response) {
                if (response.status === 'connected') { // 已登录
                    _this.onFBinfo(response.authResponse)
                    FB.logout()
                } else { // 未登录进行登录
                    FB.login(function(res) {
                        if (res.status === 'connected') {
                            _this.onFBinfo(res.authResponse)
                            FB.logout()
                        }
                    })
                }
            })
        },
        emailConfirm(){
            this.$api.login.facebookConfirm({
                appId:this.emailForm?.info?.user?.id||this.emailForm?.info?.appId, //8201515364374d2081f1499319eb6c28
                email:this.emailForm.emailInput
            }).then(response=>{
                const res = response?.result || {};
                this.$storage.set('token', res.token, true)
                this.$storage.set('userId', res.user.id, true);
                this.$storage.set('email', res.user.email);
                this.$storage.set('page/refresh', true)
                let referrer = document.referrer
                if(this.tab.type == 1) {
                    // 登录/注册弹窗
                    if(!referrer.includes('market/qrcode')) {
                        this.$storage.set('biz/hasInPopupRegister', true);
                        this.$storage.remove('biz/inPopup');
                    }
                }
                // 其他操作
                this.setOther()
                // 埋点
                this.setBuried(res)
                this.emailForm.isSuccess = true
                this.emailForm.emailInput = ''
            })
        },
        onFBinfo(e){
            this.$api.login.facebookLogin({
                identityToken: e.accessToken,
                registerSource: this.params.registerSource
            }).then(response => {
                const res = response?.result || {};
                if(res.needEmail){
                    this.emailForm.info = res
                    this.emailForm.emailPopup = true
                    return false
                }
                this.$storage.set('token', res.token, true)
                this.$storage.set('userId', res.user.id, true);
                this.$storage.set('email', res.user.email);
                this.$storage.set('page/refresh', true)
                let referrer = document.referrer
                if(this.tab.type == 1) {
                    // 登录/注册弹窗
                    if(!referrer.includes('market/qrcode')) {
                        this.$storage.set('biz/hasInPopupRegister', true);
                        this.$storage.remove('biz/inPopup');
                    }
                }
                // 其他操作
                this.setOther()
                // 埋点
                this.setBuried(res)
                // 跳转
                this.jumpCurrent()
            })
        },
        onGoogleLogin(e){ //谷歌凭证返回
            this.$api.login.googleLogin({
                identityToken: e.credential,
                registerSource: this.params.registerSource
            }).then(response => {
                const res = response?.result || {};
                this.$storage.set('token', res.token, true)
                this.$storage.set('userId', res.user.id, true);
                this.$storage.set('email', res.user.email);
                this.$storage.set('page/refresh', true)
                let referrer = document.referrer
                if(this.tab.type == 1) {
                    // 登录/注册弹窗
                    if(!referrer.includes('market/qrcode')) {
                        this.$storage.set('biz/hasInPopupRegister', true);
                        this.$storage.remove('biz/inPopup');
                    }
                }
                // 其他操作
                this.setOther()
                // 埋点
                this.setBuried(res)
                // 跳转
                this.jumpCurrent()
            })
        },
        continueLeft(){
            this.params.password = ''
            this.isContinue = false
        },
        handleContinue(){
            if(this.form.hasEmail){
                this.isloading = true
                this.loading = true
                this.$api.login.isRegisterAjax({
                        email: this.params.email
                    }).then(res=>{
                        if(res.code == "success"){
                            if(res.result==true){
                                this.tab.type = 2
                                this.see.status = false
                            }else{
                                this.tab.type = 1
                                this.see.status = true
                            }
                            this.isContinue = true
                        }
                        this.isloading = false
                        this.loading = false
                    }).catch((e)=>{
                        this.isloading = false
                        this.loading = false
                    })
            }else{
                this.form.hasEmailTip = true
            }
        },
        // 标签改变
        changeTab() {
            this.setInputStatus()
            this.checkForm()
        },
        // 设置填写状态
        setInputStatus() {
            this.form.hasAllInput = false
            if(this.params.email && this.params.password) {
                this.form.hasAllInput = true
            }
            if(!this.params.email) {
                this.form.hasEmailTip = false
            }
            if(!this.params.password) {
                this.form.hasPasswordTip = false
            }
        },
        // 输入框变化
        inputForm(obj, hasNeedOptions = true) {
            this.form.inputType = obj
            this.setInputStatus()
            if(obj == 1 && hasNeedOptions) {
                this.setEmailOptions()
            }
            // 校验
            this.checkForm()
        },
        // 输入框失去焦点
        blurForm(obj) {
            if(obj == 1) {
                let clear = setTimeout(() => {
                    this.form.hasEmailOption = false
                    clearTimeout(clear)
                }, 0)
            }
            // 校验
            this.checkForm()
        },
        // 密码校验
        setPwdStatus() {
            this.tip.hasPwdTip = false
            if(this.tab.type == 1) {
                this.params.password && (this.tip.hasPwdTip = true)
                if(this.form.inputType == 2) {
                    let statusArr = [this.form.hasPwd, this.form.hasPwdEn, this.form.hasPwdNum];
                    this.tip.list.forEach((item, i) => {
                        this.$set(this.tip.list, i, {...item, status: statusArr[i]})
                    })
                }
            }
        },
        // 邮箱设置
        setEmailStatus() {
            if(!this.form.emailOptions.length) {
                this.form.hasEmailOption = false
            }
        },
        // 邮箱选项
        setEmailOptions() {
            const i = this.params.email.indexOf('@');
            const length = this.params.email.length;
            const index = i == -1 ? length : i;
            const suffix = this.params.email.slice(index);
            const text = this.params.email.slice(0, index);
            this.form.emailOptions = []
            if(this.params.email) {
                this.form.emailSuffix.forEach(item => {
                    if(item.includes(suffix)) {
                        this.form.emailOptions.push({
                            text: `${text}`,
                            email: `${item}`,
                            value: `${text}${item}`,
                        })
                    }
                })
            }
            if(this.form.emailOptions.length) {
                this.form.hasEmailOption = true
            } else {
                this.form.hasEmailOption = false
            }
        },
        // 邮箱选择
        handleSelect(obj) {
            this.params.emailSelect = obj.value
            this.params.email = this.params.emailSelect
            this.form.hasEmailOption = false
            let clear = setTimeout(() => {
                this.handleContinue()
                clearTimeout(clear)
            }, 100)
        },
        // 清空填写
        handleClear(obj) {
            if(obj == 1) {
                this.params.email = ''
            } else {
                this.params.password = ''
            }
            this.setInputStatus()
            // 校验
            this.checkForm()
        },
        // 密码查看
        handleSee() {
            this.see.status = !this.see.status
        },
        // 关闭弹窗
        handleClose() {
            // if(this.popup.hasReload) {
            //     this.popup.visible = false
            //     this.$router.back(0)
            // } else {
            //     this.$router.back()
            // }
            if(window.history.length<=1||!this.$store.state.national.history){
                this.$router.replace('/')
            }else if(window.history.length <= 1) {
                this.$router.push({
                    name: 'Home'
                })
            } else {
                this.$router.back()
            }
        },
        // sms勾选
        handleAgree() {
            this.params.agree = !this.params.agree
        },
        // 提交
        handleSubmit() {
            // if(!this.form.hasAllInput) {
            //     return
            // }
            // 校验
            if(!this.form.hasEmail || !this.form.hasPwd) {
                this.form.hasPwd = false
                this.tab.type == 1? this.tip.hasPwdTip = true : this.form.hasPasswordTip = true
                return
            }
            this.checkForm()
            this.$storage.remove('biz/hasRegister')
            this.loading = true
            // 登录/注册
            if(this.tab.type == 1) {
                this.setRegister()
            } else {
                this.getKeyAjax()
            }
        },
        checkForm() { // 校验
            this.form.clear && clearTimeout(this.form.clear)
            this.form.clear = setTimeout(() => {
                this.form.hasEmail = validate.userName(this.params.email)
                this.form.hasEmailTip = !this.form.hasEmail
                // this.form.hasPwdNum = /\d/.test(this.params.password);
                // this.form.hasPwdEn =  /[a-zA-Z]/.test(this.params.password);
                this.form.hasPwd = validate.checkPwd(this.params.password);
                this.form.hasPasswordTip = !this.form.hasPwd
                if(!this.params.email) {
                    this.form.hasEmailTip = false
                }
                if(this.params.password == '') {
                    this.form.hasPwd = false
                    this.form.hasPasswordTip = false
                }
                this.form.hasCheck = this.form.hasPwd && this.form.hasEmail
                // 设置密码状态
                this.setPwdStatus()
                // 设置邮箱状态
                this.setEmailStatus()
            }, 50)
        },
        getKeyAjax() { // 获取秘钥接口
            this.isloading = true
            this.$api.login.pubKey().then(response => {
                const res = response?.result || {};
                let encryptor = new JSEncrypt();
                encryptor.setPublicKey(res);
                let md5Val = md5(this.params.password) + '|' + new Date().getTime();
                this.params.md5Password = encryptor.encrypt(md5Val);
                this.isloading = false
                this.setSign();
            }).catch(()=>{
                this.isloading = false
            })
        },
        setRegister() { // 注册
            this.isloading = true
            this.$api.login.registerAjax({
                email: this.params.email,
                pwd: md5(this.params.password),
                agree: this.params.agree,
                registerSource: this.params.registerSource
            }).then(response => {
                this.isloading = false
                this.loading = false
                const res = response?.result;
                res && this.getKeyAjax();
            }).catch(()=>{
                this.isloading = false
                this.loading = false
            })
        },
        setSign() { // 登录
            this.isloading = true
            this.$api.login.signAjax({
                loginName: this.params.email,
                encodeLoginPwd: this.params.md5Password,
                loginTerminal: 'H5'
            }).then(response => {
                this.isloading = false
                this.loading = false
                if(response.error_code==500){
                    if(++this.form.pwdNum>=3){
                        this.form.isPwd = true
                    }
                    return false
                }
                const res = response?.result || {};
                const referrer = document.referrer;
                this.$storage.set('token', res.token, true)
                this.$storage.set('userId', res.user.id, true);
                this.$storage.set('email', res.user.email);
                this.$storage.set('page/refresh', true)
                if(this.tab.type == 1) {
                    // 登录/注册弹窗
                    if(!referrer.includes('market/qrcode')) {
                        this.$storage.set('biz/hasInPopupRegister', true);
                        this.$storage.remove('biz/inPopup');
                    }
                    this.$storage.set('biz/hasRegister', true)
                }
                this.$storage.set('user/info', res.user)
                this.$storage.set('user/email', res.user.email)
                // 其他操作
                this.setOther()
                // 埋点
                this.setBuried(res)
                // 跳转
                this.jumpCurrent()
            }).catch(()=>{
                this.isloading = true
                this.loading = false
            })
        },
        // 当前页跳转
        jumpCurrent() {
            if(window.history.length<=1||!this.$store.state.national.history){
                this.$router.replace('/mine')
            }else if(this.popup.hasReload) {
                this.$router.back(0)
            } else {
                this.$router.back()
            }
        },
        // 协议跳转
        jumpPolicy(obj) {
            this.$router.push({
                name: 'Policy',
                query:{
                    id: obj
                }
            })
        },
        // 跳转修改密码页
        jumpChangePassword() {
            // this.$router.push({
            //     name: 'ForgotPwd'
            // })
            this.$emit('openPwd')
        },
        // other
        setOther() {
            this.$storage.remove('influencer/hasApplyStatus')
        },
        // 埋点
        setBuried(obj) {
            const currency = this.$storage.get('currency') || {};
            // fb
            this.$fnApi.buried.fbq({
                type: 'CompleteRegistration',
                params: {
                    content_name: obj.user.email,
                    currency: 'USD',
                    value: 0
                }
            }, this)
        },
        loadFBSDK() {
            (function(d, s, id) { // Load the SDK asynchronously
              var js; var fjs = d.getElementsByTagName(s)[0]
              var appId = '276967367226136'
              if (d.getElementById(id)) return
              js = d.createElement(s); js.id = id
              js.src = `https://connect.facebook.net/en_US/sdk.js#version=v3.3&appId=${appId}&status=true&cookie=true&xfbml=true&autoLogAppEvents=true`
              fjs.parentNode.insertBefore(js, fjs)
            }(document, 'script', 'facebook-jssdk'))
        },
        loadGoogle(){ //初始化谷歌api
            google.accounts.id.initialize({
                client_id: '173630304167-5j9frqunimptn90ssqoks79bmuehfdd7.apps.googleusercontent.com',
                // client_id: '317789229717-7earmmde25acb1a69rgpsljqb09hprsd.apps.googleusercontent.com',
                callback: this.onGoogleLogin,//信息回调
            });
            // google.accounts.id.prompt();
            google.accounts.id.renderButton(document.getElementById("signinDiv"), {
                theme: 'outline',
                size: 'large',
                text:this.$translate('Continue with Google'),
                width:350
            });
        },
        // 初始化设置
        initialSet() {
            const country = this.$storage.get('country') || {};
            const email = this.$storage.get('user/email') || '';
            const referrer = this.$store.state.biz.referrer;
            const sourceType = {
                'Home': 1,
                'Product': 2,
                'Bag': 3,
                'Mine': 4,
                'Wish': 5,
                'PromoCode': 6,
                'GiftCardApplication': 7
            };
            const sourceListType = ['SitemapList', 'CategoryDetail', 'Search']
            this.loadFBSDK()
            this.form.emailSuffix = this.$fnApi.mixData.emailSuffix(country.countryCode||'US')
            this.params.email = email
            this.params.email && this.inputForm(1, false)
            this.params.registerSource = sourceType[referrer] || sourceListType[referrer] || 5
            this.tip.list = [{
                text: this.$translate('8 to 16 characters'),
                status: false,
                type: 1
            },
            // {
            //     text: this.$translate('At least one letter'),
            //     status: false,
            //     type: 2
            // }, {
            //     text: this.$translate('At least one number'),
            //     status: false,
            //     type: 3
            // }
            ]
            // try {
            //    this.loadGoogle()
            // } catch (error) {
            //     window.onload = ()=>{ //初始化谷歌按钮
            //         this.loadGoogle()
            //     }
            // }
        }
    },
    computed: {},
    watch: {}
}
