
export default {
    name: 'Loading',
    components: {},
    props: {
        show: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        content: [String],
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    asyncData({api, cookies, ...context}) {},
    data() {
        return {
            visible: this.show,
            text: this.content
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        loadingOn(key) {
            return this[key] && this[key]()
        },
        getData() {
            return {
                options: this.options,
                show: this.show,
                text: this.text,
                visible: this.visible,
                content: this.content
            }
        }
    },
}
